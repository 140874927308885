(function() {
  'use strict';
  var BREAK_POINT, VIEW_WIDTH;

  BREAK_POINT = 768;

  VIEW_WIDTH = 1300;

  (function() {
    var vp, w;
    w = document.documentElement.clientWidth;
    if (BREAK_POINT <= w) {
      vp = document.querySelector('[name="viewport"]');
      if (vp) {
        return vp.content = "width=" + VIEW_WIDTH + ", initial-scale=1";
      }
    }
  })();

  $(function() {
    var $blocks, $body, $header, $html, $lnav, $slideshow, $win, alignOption, indexListAlignOption;
    $win = $(window);
    $body = $('body');
    $html = $('html');
    $('a, area').bcScrollTo();
    $('a[href], button').filter(function() {
      return !$(this).closest('.content-group--lineup').length;
    }).bcWink();
    $slideshow = $('[data-psyborg="slideshow"]');
    if ($slideshow.length) {
      $slideshow.bcImageLoaded(function() {
        var $ctrl, $marker, id, p, ref;
        $slideshow.psycle({
          transition: 'fade',
          delay: 4000,
          duration: 1200,
          resizable: true
        });
        $slideshow.find('li a, li span').bcBackground({
          outer: true
        });
        p = $slideshow.data('psycle');
        p.trigger($.PsycleEvent.RESIZE_END);
        id = $slideshow.attr('data-id');
        if (!/[a-z-_][a-z0-9-_]*/ig.test(id)) {
          if ((ref = window.console) != null) {
            if (typeof ref.warn === "function") {
              ref.warn('"data-id" attribute value is invalid.');
            }
          }
          return;
        }
        $ctrl = $('[data-psyborg="carousel-ctrl"][data-control="' + id + '"]');
        if ($ctrl.length) {
          p.ctrl($ctrl, {
            prev: '[data-psyborg="carousel-ctrl-prev"]',
            next: '[data-psyborg="carousel-ctrl-next"]'
          });
        }
        $slideshow.find('.link-box__thumb, .link-line__thumb').bcImageLoaded(function() {
          return $(this).bcBackground({
            outer: true
          });
        });
        $marker = $('[data-psyborg="carousel-marker"][data-for="' + id + '"]');
        if ($marker.length) {
          return p.marker().appendTo($marker);
        }
      });
    }
    $('[data-psyborg="carousel"]').bcImageLoaded(function() {
      var $crsl, $ctrl, $marker, id, p, ref;
      $crsl = $(this);
      $crsl.psycle({
        transition: 'slide',
        repeat: 'loop',
        auto: false,
        resizable: true,
        draggable: true,
        swipeable: true
      });
      p = $crsl.data('psycle');
      id = $crsl.attr('data-id');
      if (!/[a-z-_][a-z0-9-_]*/ig.test(id)) {
        if ((ref = window.console) != null) {
          if (typeof ref.warn === "function") {
            ref.warn('"data-id" attribute value is invalid.');
          }
        }
        return;
      }
      $ctrl = $('[data-psyborg="carousel-ctrl"][data-control="' + id + '"]');
      if ($ctrl.length) {
        p.ctrl($ctrl, {
          prev: '[data-psyborg="carousel-ctrl-prev"]',
          next: '[data-psyborg="carousel-ctrl-next"]'
        });
      }
      $crsl.find('.link-box__thumb, .link-line__thumb').bcImageLoaded(function() {
        return $(this).bcBackground({
          outer: true
        });
      });
      $marker = $('[data-psyborg="carousel-marker"][data-for="' + id + '"]');
      if ($marker.length) {
        p.marker().appendTo($marker);
      }
    });
    if ($body.hasClass('page-home')) {
      $header = $('.header');
      $win.on('resize scroll', function() {
        
			var y = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
			;
        var height;
        height = typeof $header.height === "function" ? $header.height() : void 0;
        if (height && (window.document.documentElement.clientHeight - height) < y) {
          $html.addClass('js-nav-fixed');
        } else {
          $html.removeClass('js-nav-fixed');
        }
      });
    }
    $.fn._mega = function(opt) {
      var $target, SVG_NS, click, config, countdown, createSVGElement, enter, getRect, hitArea, hitAreaPolygon, remove, timer;
      SVG_NS = 'http://www.w3.org/2000/svg';
      timer = 0;
      $target = null;
      config = $.extend({
        delay: 200,
        trigger: '>a',
        subMenuBase: '>ul',
        subMenuArea: '>ul >*:first-child',
        vector: 'to bottom'
      }, opt);
      createSVGElement = function(tag) {
        return document.createElementNS(SVG_NS, tag);
      };
      hitArea = createSVGElement('svg');
      hitAreaPolygon = createSVGElement('polygon');
      hitAreaPolygon.setAttribute('fill', 'transparent');
      hitAreaPolygon.setAttribute('style', 'cursor: pointer;');
      hitArea.appendChild(hitAreaPolygon);
      getRect = function($el) {
        return $el[0].getBoundingClientRect();
      };
      enter = function(e) {
        var $subMenuArea, $subMenuBase, $this, points, subMenuAreaRect, subMenuBase, subMenuBaseRect, targetRect;
        $this = $(this);
        $target = $this.find(config.trigger);
        if (!$target.length) {
          return;
        }
        $subMenuBase = $this.find(config.subMenuBase);
        subMenuBase = $subMenuBase[0];
        if (!$subMenuBase.length) {
          return;
        }
        $subMenuArea = $this.find(config.subMenuArea);
        if (!$subMenuArea.length) {
          return;
        }
        targetRect = getRect($target);
        subMenuBaseRect = getRect($subMenuBase);
        subMenuAreaRect = getRect($subMenuArea);
        switch (config.vector) {
          case 'to bottom':
            points = [[e.clientX, e.clientY], [subMenuAreaRect.left + subMenuAreaRect.width, targetRect.height], [subMenuAreaRect.left, targetRect.height]];
            hitArea.setAttribute('width', subMenuBaseRect.width);
            hitArea.setAttribute('height', targetRect.height);
            hitArea.setAttribute('style', "position: absolute; top: " + (targetRect.height * -1) + "px; left: 0;");
            break;
          case 'to right':
            points = [[0, targetRect.top - subMenuAreaRect.top], [targetRect.width, 0], [targetRect.width, subMenuAreaRect.height], [0, targetRect.top - subMenuAreaRect.top + targetRect.height]];
            hitArea.setAttribute('width', targetRect.width);
            hitArea.setAttribute('height', subMenuBaseRect.height);
            hitArea.setAttribute('style', "position: absolute; top: 0px; left: " + (targetRect.width * -1) + "px;");
        }
        hitAreaPolygon.setAttribute('points', points.join());
        subMenuBase.appendChild(hitArea);
        countdown();
      };
      countdown = function() {
        clearTimeout(timer);
        timer = setTimeout(remove, config.delay);
      };
      remove = function() {
        var ref;
        clearTimeout(timer);
        if ((ref = hitArea.parentNode) != null) {
          ref.removeChild(hitArea);
        }
      };
      click = function() {
        if ($target && $target[0].href) {
          location.href = $target[0].href;
        }
      };
      this.on('mouseenter', enter);
      hitAreaPolygon.addEventListener('mousemove', countdown, false);
      hitAreaPolygon.addEventListener('mouseout', remove, false);
      hitArea.addEventListener('mouseup', click, false);
      return this;
    };
    $('.nav-global__item')._mega({
      subMenuBase: '.mega-dropdown',
      subMenuArea: '.mega-dropdown__inner',
      vector: 'to bottom'
    });
    $('.nav-global__item [data-sitemap="page-category"]')._mega({
      subMenuArea: '>ul',
      vector: 'to right'
    });
    $('.nav-global__item').on('mouseenter', function() {
      var $list, $mdd;
      $mdd = $(this).find('.mega-dropdown');
      $list = $mdd.find('.mega-dropdown__menu-list ul li');
      if ($mdd.length && $list.length) {
        $body.addClass('is-nav-hovered');
      } else {
        $body.removeClass('is-nav-hovered');
      }
    }).on('mouseleave', function() {
      $body.removeClass('is-nav-hovered');
    });
    $('[data-toggle-for]').each(function() {
      var $target, $toggle, DUR, err, error, id, statusKeyName;
      DUR = 500;
      $toggle = $(this);
      id = $toggle.data('toggleFor');
      statusKeyName = "data-toggle-status-" + id;
      if (id) {
        try {
          $target = $("#" + id);
          return $toggle.on('click', function() {
            if ('open' === $body.attr(statusKeyName)) {
              $target.stop(true, true).slideUp(DUR, function() {
                return $body.attr(statusKeyName, 'close');
              });
            } else {
              $target.stop(true, true).slideDown(DUR);
              $body.attr(statusKeyName, 'open');
            }
            return false;
          });
        } catch (error) {
          err = error;
        }
      }
    });
    alignOption = {};
    alignOption.defaults = 3;
    alignOption[BREAK_POINT] = 2;
    $('.content-group--page-post .link-box .post-info__title').bcBoxAlignHeight(alignOption);
    $('.content-group--page-post .link-box .post-info__description').bcBoxAlignHeight(alignOption);
    $('.content-group--page-post .link-box .post-info').bcBoxAlignHeight(alignOption);
    indexListAlignOption = {};
    indexListAlignOption.defaults = 3;
    indexListAlignOption[BREAK_POINT] = 1;
    $('.index-list').bcBoxAlignHeight(indexListAlignOption, '.bge-ckeditor h4 + p');
    $('.link-box__thumb, .link-line__thumb').filter(function() {
      return !$(this).closest('[data-psyborg="carousel"]').length;
    }).bcImageLoaded(function() {
      return $(this).bcBackground({
        outer: true
      });
    });
    $('[data-baser="Maps"][data-lat][data-lng]').bcMaps();
    if ($.fn.sight) {
      $blocks = $('[data-bgb]').sight();
      $blocks.on('sightenter sightshow', function() {
        return $(this).addClass('js-sight');
      });
    }
    $lnav = $('.nav-local__current');
    if ($lnav.length) {
      $lnav.on('click', function() {
        var $nav, $this;
        $this = $(this);
        $nav = $this.next();
        $nav.slideToggle();
        return $this.toggleClass('nav-local__current--open');
      });
    }
    return $(document).on('contextmenu', 'img', function(e) {
      return false;
    });
  });

}).call(this);
